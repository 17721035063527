import Vue from "vue";
import App from "./App.vue";
import store from "./store";
import router from "./router";
import "vue2-datepicker/index.css";

new Vue({
	store,
	router,
	render: (h) => h(App),
}).$mount("#app");
