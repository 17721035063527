import Vue from "vue";
import Vuex from "vuex";
import axios from "axios";
import getters from "./moduls/function";
import api from "./plugin/api";
import local from "./local";
Vue.use(Vuex);

const url = "";
const mainBaseUrl = "https://authors.uz";
//const url = "http://127.0.0.1:5010";
//const mainBaseUrl = "http://127.0.0.1:5004";
var http = axios.create({ baseURL: url });
Vue.use(api, { http });
Vue.prototype.$j = function (...keys) {
	if (!keys) return this.$store.state.lang;
	if (keys) {
		switch (keys.length) {
			case 1: return this.$store.state.lang[keys[0]];
			case 2: return this.$store.state.lang[keys[0]][keys[1]];
		}
	}
};
const store = new Vuex.Store({
	state: {
		url,
		mainBaseUrl,
		lang: local.rus,
		local,
		langKod: "",
	},
	getters
});
export default store;