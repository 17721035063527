import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);
import Layout from "@/layout";
import Account from "@/layout/account";

const routes = [
    {
        path: "*",
        component: () => import("@/views/error-page/404"),
        hidden: true
    },
    {
        path: "/400",
        component: () => import("@/views/error-page/400"),
        hidden: true
    },
    {
        path: "/401",
        component: () => import("@/views/error-page/401"),
        hidden: true
    },
    {
        path: "/404",
        component: () => import("@/views/error-page/404"),
        hidden: true
    },
    {
        path: "/500",
        component: () => import("@/views/error-page/500"),
        hidden: true
    },
	{
        path: "/",
        component: Layout,
        children: [
            {
                path: "",
                component: () => import("@/views/main/index")
            },
			{
				path: "certificate/:id",
				component: () => import("@/views/main/certificate")
			},
            {
                path: "page/:id",
                component: () => import("@/views/pages/view"),
            },
        ]
    },
	{
        path: "/about",
        component: Layout,
        redirect: "/about/index",
        hidden: false,
        children: [
            {
                path: "index",
                component: () => import("@/views/about/index")
            },
            {
                path: "leadership",
                component: () => import("@/views/about/leadership")
            }
        ]
    },
    {
        path: "/service",
        component: Layout,
        redirect: "/service/index",
        hidden: false,
        children: [
            {
                path: "index",
                component: () => import("@/views/service/index")
            },
            {
                path: "deposition",
                component: () => import("@/views/service/deposition")
            }
        ]
    },
    {
        path: "/news",
        component: Layout,
        redirect: "/news/index",
        hidden: false,
        children: [
            {
                path: "index",
                component: () => import("@/views/news/index")
            },
            {
                path: "detail/:id",
                component: () => import("@/views/news/detail")
            },
        ]
    },
	{
		path: "/soon",
		component: () => import("@/views/main/soon"),
	},
    {
        path: "/pages",
        component: Layout,
        redirect: "/",
        hidden: false,
        children: [
            {
                path: "view/:id",
                component: () => import("@/views/pages/view")
            },
            {
                path: "union",
                component: () => import("@/views/pages/union")
            },
            {
                path: "partner",
                component: () => import("@/views/pages/partner")
            },
            {
                path: "contact",
                component: () => import("@/views/pages/contact")
            }
        ]
    },
    {
        path: "/account",
        component: Account,
        redirect: "/account/profile",
        hidden: false,
        children: [
            {
                path: "profile",
                component: () => import("@/views/account/profile")
            },
            {
                path: "application",
                component: () => import("@/views/account/application")
            },
        ]
    },
];

const router = new VueRouter({
	routes,
	mode:'history'
});

export default router;
