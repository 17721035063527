const getters = {
	toastr: () => (_this, type, key, text) => {
		let keyText = _this.$j(key);
		let typeText = _this.$j(text);
		if (keyText == null) { keyText = key; }
		if (typeText == null) { typeText = text; }
		toastr[type](keyText, typeText, {
			progressBar: true,
			timeOut: "5000",
			extendedTimeout: "0"
		});
		return false;
	},
	formatDate: () => (dateString) => {
		const date = new Date(dateString);
		const day = String(date.getDate()).padStart(2, '0');
		const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-indexed
		const year = date.getFullYear();

		return `${year}-${month}-${day}`;
	},
	changeLang: (state) => (lang) => {
		state.langKod = lang;
		state.lang = state.local[lang];
	},
	// readCookie:() => (name) => {
	// 	var result = document.cookie.match(new RegExp(name + "=([^;]+)"));
	// 	result && (result = JSON.parse(result[1]));
	// 	return result;
	// },
	getToken:() => () => {
		let result = null;
		let cookie = getters.getCookie("accessCookie");
		if(cookie){
			let user = JSON.parse(cookie);
			result = user.token;
		}
		return result;
	},
	getLogin:() => () => {
		let login = null;
		let cookie = getters.getCookie("accessCookie");
		if(cookie){
			let user = JSON.parse(cookie);
			login = user.userName;
		}
		return login;
	},
	getLevel:() => () => {
		let level = null;
		let cookie = getters.getCookie("accessCookie");
		if(cookie){
			let user = JSON.parse(cookie);
			level = user.userLevel;
		}
		return level;
	},
	getHeader:() => (_this) => {
		let token = _this.$store.getters.getToken();
		let headers = {
			"Content-Type": "application/json",
			Authorization: "Bearer " + token,
		};
		return headers;
	},
	getUserModel:() => () => {
		let user = null;
		let cookie = getters.getCookie("userCookie");
		if(cookie){
			user = JSON.parse(cookie);
		}
		return user;
	},
	getCookie(cname) {
		let name = cname + "=";
		let ca = document.cookie.split(';');
		for(let i = 0; i < ca.length; i++) {
			let c = ca[i];
			while (c.charAt(0) == ' ') {
				c = c.substring(1);
			}
			if (c.indexOf(name) == 0) {
				return c.substring(name.length, c.length);
			}
		}
		return "";
	},
  	deleteCookie:() => (name) => {
		document.cookie = name + '=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
	},
};

export default getters;