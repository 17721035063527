<template>
	<div class="ttr-sidebar">
        <div class="ttr-sidebar-wrapper content-scroll">
            <!-- side menu logo start -->
            <div class="ttr-sidebar-logo d-flex justify-content-between align-items-center">
                <a href="/"><img alt="" src="/assets/img/logo.png" width="130" height="27"></a>
                <div class="ttr-sidebar-toggle-button">
                    <i class="ti-arrow-left"></i>
                </div>
            </div>
            <!-- side menu logo end -->
            <!-- sidebar menu start -->
            <nav class="ttr-sidebar-navi">
                <ul>
                    <li>
                        <a href="/account/profile" class="ttr-material-button">
                            <span class="ttr-icon"><i class="ti-user"></i></span>
                            <span class="ttr-label">{{ $j('Cabinet') }}</span>
                        </a>
                    </li>
                    <li>
                        <a href="/account/application" class="ttr-material-button">
                            <span class="ttr-icon"><i class="ti-receipt"></i></span>
                            <span class="ttr-label">{{ $j('Applications') }}</span>
                        </a>
                    </li>
                    <!-- <li>
                        <a href="#" class="ttr-material-button">
                            <span class="ttr-icon"><i class="ti-user"></i></span>
                            <span class="ttr-label">My Profile</span>
                            <span class="ttr-arrow-icon"><i class="mdi mdi-chevron-down"></i></span>
                        </a>
                        <ul>
                            <li>
                                <a href="user-profile.html" class="ttr-material-button"><span class="ttr-label">User Profile</span></a>
                            </li>
                            <li>
                                <a href="teacher-profile.html" class="ttr-material-button"><span class="ttr-label">Teacher Profile</span></a>
                            </li>
                        </ul>
                    </li> -->
                    <li class="ttr-seperate"></li>
                </ul>
                <!-- sidebar menu end -->
            </nav>
            <!-- sidebar menu end -->
        </div>
    </div>
</template>
<script>
    export default {
        data() {
            return {
			};
        },
		mounted(){
		}
    };
</script>