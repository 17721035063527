<template>
	<router-view />
</template>

<script>
export default {
	components: {
		
	},
	created() {
		let lang = localStorage.getItem("lang");
		if (lang) {
			this.$store.getters.changeLang(lang);
		} else {
			localStorage.setItem("lang", "uzb");
			this.$store.getters.changeLang("uzb");
		}  
	},
};
</script>
