<template>
	<div class="ttr-pinned-sidebar ttr-opened-sidebar ttr-body">
		<headers></headers>
		<sidebar></sidebar>
		<main class="ttr-wrapper">
			<router-view />
		</main>
	</div>
</template>

<script>
import Headers from "./components/navbar.vue";
import Sidebar from "./components/sidebar.vue";
export default {
	components: {
		Headers,
		Sidebar,
	},
	created() {
		if (!this.$store.getters.getToken()) {
			this.$router.push({ path: "/" });
		}
	},
}
</script>