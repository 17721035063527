<template>
	<div id="app">
		<div id="js-preloader" class="js-preloader">
			<div class="preloader-inner">
				<span class="dot"></span>
				<div class="dots">
					<span></span>
					<span></span>
					<span></span>
				</div>
			</div>
		</div>
		<headers></headers>
		<div class="modal fade" id="oneIdModal">
			<div class="modal-dialog modal-dialog-centered">
				<div class="modal-content">
					<div class="modal-header align-items-center">
						<h4 class="modal-title">Shaxsiy saxifaga kirish</h4>
						<button type="button" class="close modal_close" data-dismiss="modal"><i class="ti-close"></i></button>
					</div>
					<div class="modal-body popupBody">
						<div class="one-id-span">
							<span>Tizimga One ID bilan kiring.</span>
						</div>
						<button class="cursor-pointer one-id-button" @click="userLogin()"> 
							<img src="/assets/img/one-id.svg" alt="" class="img-fluid">
						</button>
					</div>
				</div>
			</div>
		</div>
		<router-view />
		<footers></footers>
	</div>
</template>

<script>
import Headers from "./components/header.vue";
import Footers from "./components/footer.vue";
export default {
	components: {
		Headers,
		Footers,
	},
	methods: {
		userLogin() {
			goOneId();
		}
	}
}
</script>