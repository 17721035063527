<template>
    <header class="main_menu">
        <div class="header-top primary-bg">
            <div class="container ">
                <div class="row">
                    <div class="col-xl-6 col-lg-6 col-md-6 col-12">
                        <div class="header-contact-info d-flex">
                            <div class="header-contact header-contact-phone">
                                <span class="mdi mdi-phone"></span>
                                <p class="phone-number">+998712092002</p>
                            </div>
                            <div class="header-contact header-contact-email">
                                <span class="ti-email"></span>
                                <p class="email-name">avtorfilm@gmail.com</p>
                            </div>
                        </div>
                    </div>
                    <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12">
                        <div class="text-right d-flex justify-content-end">
                            <ul class="header-social-icon-list">
                                <li><a href="#"><span class="mdi mdi-facebook"></span></a></li>
                                <!-- <li><a href="#"><span class="mdi mdi-twitter"></span></a></li> -->
                                <li><a href="#"><span class="mdi mdi-instagram"></span></a></li>
                                <!-- <li><a href="#"><span class="mdi mdi-youtube"></span></a></li> -->
                                <li><a href="#"><span class="mdi mdi-telegram"></span></a></li>
                            </ul>
                            <ul class="header-lang-bx ml-4">
                                <li>
                                    <select class="selectpicker" data-width="fit" @change="langChange($event)">
                                        <option data-content='<span class="flag-icon flag-icon-uz"></span> O‘zbek' value="uzb"></option>
                                        <option data-content='<span class="flag-icon flag-icon-ru"></span> Русский' value="rus"></option>
                                        <option data-content='<span class="flag-icon flag-icon-gb"></span> English' value="eng"></option>
                                    </select>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="container">
            <div class="row align-items-center">
                <div class="col-lg-12 col-xl-12">
                    <nav class="navbar navbar-expand-lg navbar-light">
                        <a class="navbar-brand" href="/"> <img src="/assets/img/logo.png" alt="logo"> </a>
                        <button class="navbar-toggler" type="button" data-toggle="collapse"
                            data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent"
                            aria-expanded="false" aria-label="Toggle navigation">
                            <span class="navbar-toggler-icon"></span>
                        </button>

                        <div class="collapse navbar-collapse main-menu-item justify-content-end" id="navbarSupportedContent">
                            <ul class="navbar-nav align-items-center">
                                <li class="nav-item dropdown">
                                    <a class="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                        {{ $j('AboutUs') }}
                                    </a>
                                    <div class="dropdown-menu navbar-menu" aria-labelledby="navbarDropdown">
                                        <a class="dropdown-item" href="/page/about-association">{{ $j('AboutAssociation') }}</a>
                                        <a class="dropdown-item" href="/about/leadership">{{ $j('Management') }}</a>
                                        <a class="dropdown-item" href="#">{{ $j('Documents') }}</a>
                                        <a class="dropdown-item" href="/page/partners">{{ $j('Partners') }}</a>
                                        <a class="dropdown-item" href="/page/contact">{{ $j('Contacts') }}</a>
                                    </div>
                                </li>
                                <li class="nav-item"><a class="nav-link" href="/service/deposition">{{ $j('Deposition') }}</a></li>
                                <li class="nav-item"><a class="nav-link" href="#">{{ $j('RightsHolders') }}</a></li>
                                <li class="nav-item"><a class="nav-link" href="#">{{ $j('Users') }}</a></li>
                                <li class="nav-item"><a class="nav-link" href="#">{{ $j('Registers') }}</a></li>
                                <li class="nav-item"><a class="nav-link" href="/news">{{ $j('News') }}</a></li>
                                <li class="nav-item dropdown" v-if="login == null">
                                    <div class="gradient-button">
                                        <button type="button" class="genric-btn radius login-oneid-button" data-toggle="modal" data-target="#oneIdModal">
                                            <i class="fa fa-sign-in-alt"></i> {{ $j('Login') }} <i class="mdi mdi-arrow-right"></i>
                                        </button>
                                    </div>
                                </li>                                
                                <li class="nav-item dropdown" v-else>
                                    <a class="nav-link dropdown-toggle d-flex align-items-center" href="#" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                        <span class="ttr-user-avatar">
                                            <img alt="" src="/assets/img/users/avatar.jpg" width="32" height="32">
                                        </span>
                                    </a>
                                    <div class="dropdown-menu" aria-labelledby="navbarDropdown">
                                        <a class="dropdown-item" href="/admin" v-if="level == 'SiteOperator'">{{ $j('Cabinet') }}</a>
                                        <a class="dropdown-item" href="/account/profile" v-else>{{ $j('Cabinet') }}</a>
                                        <a class="dropdown-item" href="javascript:void(0);" @click="logout()">{{ $j('Logout') }}</a>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </nav>
                </div>
            </div>
        </div>
    </header>
</template>
<script>
    export default {
        data() {
            return {
                login: null,
                level: null,
            };
        },
        methods: {
            logout() {
                this.$store.getters.deleteCookie("accessCookie");
                this.$store.getters.deleteCookie("userCookie");
                location.href = "/";
            },
            lang(){
                let lang = localStorage.getItem("lang");
                $(".selectpicker").selectpicker('val', lang)
            },
            langChange(event) {
                let lang = event.currentTarget.value;
                localStorage.setItem("lang", lang);
                this.$store.getters.changeLang(lang);
                //window.location.reload();
                console.log(this.$store.state.langKod);
            }
        },
        mounted() {
            this.lang();
            this.login = this.$store.getters.getLogin();
            this.level = this.$store.getters.getLevel();
        }
    };
</script>