export default {
  Homepage: "Bosh sahifa",
  Login: "Kirish",
  Cabinet: "Kabinet",
  Logout: "Chiqish",
  AboutAssociation: "Uyushma haqida",
  Leadership: "Rahbariyat",
  Documents: "Hujjatlar",
  Partners: "Hamkorlar",
  Contacts: "Kontaktlar",
  Deposition: "Deponentlash",
  RightsHolders: "Huquq egalari",
  Users: "Foydalanuvchilar",
  Registers: "Reyestrlar",
  News: "Yangiliklar",
  WelcomeSite: "Mualliflar uyushmasiga xush kelibsiz",
  SiteTitle: "O‘zbekiston kino sanoati vakillari uyushmasi",
  SiteDescription:
    "Audiovizual sohada mualliflar huquqlarini jamoaviy asosda boshqarishni amalga oshirish orqali mualliflik huquqini himoya qilishning samarali tizimini shakllantirishdan iborat",
  OurPartner: "Bizning hamkorlar",
  GeneralInformation: "umumiy ma’lumot",
  AuthorsUnion: "Mualliflar uyushmasi",
  AuthorsUnionText1:
    "Mualliflar uyushmasi, yozuvchilar va adabiyotshunoslar jamiyatining birlikda faollik ko'rsatish, adabiyotni rivojlantirish va tarqatish, yozuvchilar orasidagi hamkorlikni oshirish maqsadida tashkil etilgan bir tashkilotdir.",
  AuthorsUnionText2:
    "Bu uyushma, O'zbekiston Respublikasi Xalq talimi vazirligi, Oliy va orta maxsus talim vazirligi, Ozbekiston Yozuvchilar uyushmasi bilan birgalikda faoliyat yuritadi.",
  AuthorsUnionText3:
    "Mualliflar uyushmasi, adabiyot sohasidagi yaratuvchi va ilmiy-tadqiqot ishlari bilan shug'ullanuvchi shaxslar orasidagi hamkorlikni oshirish, adabiyotni tarqatish, yozuvchilar orasidagi aloqalarni rivojlantirish, yosh yozuvchilar va talabalar bilan faollik ko'rsatish maqsadida faoliyat yuritadi.",
  More: "Batafsil",
  YearsOfExperience: "Yillik tajriba",
  Employees: "Hodimlar",
  Management: "Boshqaruv",
  PhotoMaterials: "Fotomateriallar",
  VideoMaterials: "Videomateriallar",
  All: "Barchasi",
  AboutUs: "Biz haqimizda",
  Media: "Media",
  Teleradio: "Teleradiokompaniyalar",
  Cinemas: "Kinoteatralar",
  CafeRestaurants: "Kafe va restoranlar",
  Shopping: "Savdo komplexlari",
  Hotels: "Mexmonxonalar",
  DataEntry: "Ma’lumotlarni kiritish",
  FileUpload: "Fayl yuklash",
  DataVerification: "Ma’lumotlarni tekshirish",
  Payment: "To‘lov qilish",
  DepositionProcess: "Deponentlash jarayoni",
  ProcessText:
    "Har qanday asarning 5 GB gacha bo'lgan faylini 2 dan 10 yilgacha deponentlashtirishingiz mumkin.",
  HandwritingName: "Asarning nomi",
  Next: "Keyingi",
  Prev: "Oldingi",
  DropFiles: "Fayllarni shu yerga tashlang yoki",
  SelectFromDevice: "Qurilmadan faylni tanlang",
  Customer: "Mijoz",
  Address: "Manzil",
  PassportSerialNumber: "Passport seriya raqami",
  DownloadedFile: "Yuklangan fayl",
  ServicePrice: "Xizmat narxi",
  Save: "Saqlash",
  PaymentText:
    "Saytda to‘lovni amalga oshirish uchun taklif qilingan to‘lov usullaridan birini tanlang: Payme, Click. Keyin to‘lov tizimi web-saytidagi ko‘rsatmalarga amal qiling",
  PaymentText2:
    "To‘lovni amalga oshirgandan so‘ng arizangizning holatini tekshiring va tasdiqlanishini kuting.",
  ProceedToPayment: "To‘lovga o‘tish",
  Applications: "Arizalar",
  PersonalCabinet: "Shaxsiy kabinet",
  PersonalInformation: "Shaxsiy ma’lumotlar",
  AddressInformation: "Manzil ma’lumotlari",
  PassportInformation: "Passport ma’lumotlari",
  DateOfBirth: "Tug‘ilgan sanasi",
  PhoneNumber: "Telefon raqami",
  WhenGiven: "Qachon berilgan",
  GivenByWho: "Kim tomonidan berilgan",
  Author: "Muallifi",
  RegistrationDate: "Ro‘yxatga olish sanasi	",
  Status: "Ariza holati",
  Status1: "Ariza yaratildi",
  Status2: "To‘lov jarayonida",
  Status3: "To‘landi",
  PieceType: "Asarning turi",
  OwnerRights: "Huquq egasi",
  PieceDate: "Asar yaratilgan sana",
  OrgAddress: "Joylashgan manzili",
  Director: "Rahbarning F.I.O",
  notEntered: "kiritilmadi!",
  notSelected: "tanlanmadi!",
  errorEntered: "notoʻgʻri kiritildi!",
};